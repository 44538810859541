// import this after install `@mdi/font` package
import "@mdi/font/css/materialdesignicons.css";
import {
  mdiCalendar,
  mdiCalendarEnd,
  mdiCalendarStart,
  mdiCallSplit,
  mdiChevronDoubleRight,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronUp,
  mdiClock,
  mdiClose,
  mdiCloseCircle,
  mdiMapMarker,
  mdiMinus,
  mdiPlus,
  mdiTicket,
} from "@mdi/js";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";

export default defineNuxtPlugin((app) => {
  const shopTheme = getDefaultTheme();

  const vuetify = createVuetify({
    ssr: true,
    defaults: {
      VCard: {
        elevation: 1,
      },
    },
    icons: {
      defaultSet: "mdi",
      aliases: {
        ...aliases,
        ticket: mdiTicket,
        chevronUp: mdiChevronUp,
        chevronDown: mdiChevronDown,
        minus: mdiMinus,
        plus: mdiPlus,
        doubleChevronRight: mdiChevronDoubleRight,
        chevronRight: mdiChevronRight,
        callSplit: mdiCallSplit,
        close: mdiClose,
        closeCircle: mdiCloseCircle,
        mapMarker: mdiMapMarker,
        calendar: mdiCalendar,
        calendarStart: mdiCalendarStart,
        calendarEnd: mdiCalendarEnd,
        clock: mdiClock,
      },
      sets: {
        mdi,
      },
    },
    components: {},
    theme: {
      defaultTheme: "shopTheme",
      themes: {
        shopTheme,
      },
    },
  });
  app.vueApp.use(vuetify);
});
