import { defineStore, storeToRefs } from "pinia";
import type { Ref } from "vue";
import type { ShopOrderArray } from "~/_types/shop/product";
import {
  mappedProdArraySchemaLocalStorage,
  type mappedProdArrayType,
  type mappedProdSchemaType,
} from "~/_types/store/shop/mappedProducts";
import { useItemSelectionStore } from "~/stores/itemSelection";
import { useShopStore } from "~/stores/shop";
import type { RouteLocationNormalizedLoaded } from "vue-router";

export const useProductSelectionStore = defineStore("productSelection", () => {
  const shopStore = useShopStore();
  const { shopToken, localstorageKey } = storeToRefs(shopStore);

  const itemStore = useItemSelectionStore();
  const { selectedItemIds } = storeToRefs(itemStore);

  const orderStore = useOrderStore();

  const resumeOrderDialog: Ref<boolean> = ref(false);
  const products: Ref<ShopOrderArray> = ref([]);
  const mappedProducts: Ref<mappedProdArrayType> = ref([]);

  const nixProduct: Ref<any> = ref(null);
  const nixDialog: Ref<boolean> = ref(false);
  const nix: Ref<boolean> = useCookie("ptp_nix", {
    default: () => false,
  });

  const route: Ref<RouteLocationNormalizedLoaded> = useRouter().currentRoute;
  const localePath = useLocalePath();

  async function getStoredSelection() {
    if (!localstorageKey.value) {
      return;
    }

    const parsed = mappedProdArraySchemaLocalStorage.safeParse(
      useSessionStorage(localstorageKey.value, { mappedProducts: [] }).value
    );

    if (parsed.success) {
      mappedProducts.value = parsed.data.mappedProducts;
    }

    await orderStore.mapProducts(mappedProducts.value);
    return;
  }

  async function emptyStoredSelection() {
    if (!localstorageKey.value) {
      return;
    }

    // Clear the session storage for the specific key
    useSessionStorage(localstorageKey.value, { mappedProducts: [] }).value = {
      mappedProducts: [],
    };

    // Reset the mappedProducts variable
    mappedProducts.value = [];

    // Optionally update the store to reflect the changes
    await orderStore.mapProducts(mappedProducts.value);
  }

  function checkExistingOrder() {
    // Check if localStorageKey is loaded
    if (!localstorageKey.value) {
      return;
    }

    // Check if user already has an order (possibly used the back button)
    if (mappedProducts.value.length > 0) {
      return;
    }

    // Parse session storage data
    const sessionStorageData = useSessionStorage(localstorageKey.value, {
      mappedProducts: [],
    }).value;
    const parsed =
      mappedProdArraySchemaLocalStorage.safeParse(sessionStorageData);

    if (!parsed.success) {
      return;
    }

    const { mappedProducts: storedMappedProducts } = parsed.data;

    // Check if there are mapped products to resume the order
    if (storedMappedProducts && storedMappedProducts.length > 0) {
      resumeOrderDialog.value = true;
    }
  }

  /**
   * Automatically save stored order to session storage.
   */
  watch(
    mappedProducts,
    (val) => {
      useSessionStorage<{
        mappedProducts: mappedProdArrayType | [];
      }>(localstorageKey.value, { mappedProducts: [] }).value.mappedProducts =
        val;
    },
    {
      deep: true,
    }
  );

  /**
   * Add one unit of this item to the selection.
   * Blocks when it can't be added.
   *
   * @param product_id
   * @param quick_menu
   */
  async function addProduct(product_id: string, quick_menu: boolean = false) {
    const prod_items_id = computed(() => {
      if (selectedItemIds.value.length === 0 || quick_menu) {
        return product_id;
      }
      const concItemIds = selectedItemIds.value
        .map((item) => item.id)
        .join("_");
      return `${concItemIds}_${product_id}`;
    });

    const mappedProd = mappedProducts.value.find(
      (mappedProduct: mappedProdSchemaType) =>
        mappedProduct.prod_items_id === prod_items_id.value
    );

    if (mappedProd?.prod_items_id === prod_items_id.value) {
      mappedProd.count += 1;
    } else {
      mappedProducts.value.push({
        prod_items_id: prod_items_id.value,
        id: product_id,
        count: 1,
        subProducts: quick_menu ? [] : selectedItemIds.value,
      });
    }

    if (!quick_menu) {
      selectedItemIds.value = [];
    }

    return orderStore.mapProducts(mappedProducts.value);
  }

  /**
   * Remove one unit of item from the selection.
   * Remove all if removing one drops below the min per order.
   *
   * @param product_id
   * @param all
   */
  function removeProduct(product_id: string, all: boolean = false) {
    const updatedProducts: mappedProdArrayType = [];

    for (const product of mappedProducts.value) {
      if (product.prod_items_id === product_id) {
        if (all || product.count - 1 < 1) {
          // Skip this product
        } else {
          product.count -= 1;
          if (product.count > 0) {
            updatedProducts.push(product); // Keep the product if count > 0
          }
        }
      } else {
        updatedProducts.push(product); // Keep other products
      }
    }

    mappedProducts.value = [...updatedProducts];

    orderStore.mapProducts(mappedProducts.value);
  }

  function allergyIcon(name: string) {
    switch (name) {
      case "gluten": {
        return { icon: "mdi-barley", color: "brown darken-3" };
      }
      case "peanuts":
      case "nuts": {
        return { icon: "mdi-peanut", color: "amber darken-3" };
      }
      case "milk":
      case "lactose": {
        return { icon: "mdi-beer-outline", color: "blue darken-3" };
      }
      case "soy": {
        return { icon: "mdi-soy-sauce", color: "yellow darken-4" };
      }
      case "eggs": {
        return { icon: "mdi-egg", color: "yellow darken-3" };
      }
      case "fish":
      case "shellfish": {
        return { icon: "mdi-fish", color: "blue darken-3" };
      }
      case "wheat": {
        return { icon: "mdi-wheat", color: "brown darken-3" };
      }
      case "tree-nut":
      case "celery": {
        return { icon: "mdi-tree", color: "brown darken-4" };
      }
      case "sesame": {
        return { icon: "mdi-sesame", color: "brown darken-2" };
      }
      case "lupine": {
        return { icon: "mdi-flower-pollen", color: "brown darken-2" };
      }
      default: {
        return { icon: "mdi-alert", color: "red darken-3" };
      }
    }
  }

  function deleteSelection() {
    // products.value = null;

    navigateTo(localePath(`/shop/${route.value.params["shop_id"]}`));
  }

  return {
    nixDialog,
    nixProduct,
    nix,
    mappedProducts,
    products,
    resumeOrderDialog,
    addProduct,
    removeProduct,
    allergyIcon,
    deleteSelection,
    getStoredSelection,
    checkExistingOrder,
    emptyStoredSelection,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useProductSelectionStore, import.meta.hot)
  );
}
