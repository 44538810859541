import { defineStore, storeToRefs } from "pinia";
import type { ShopProduct } from "~/_types/shop/product";
import { useProductStore } from "~/stores/product";

export const useItemSelectionStore = defineStore("itemSelection", () => {
  const productsStore = useProductStore();
  const { productData } = storeToRefs(productsStore);

  /**
   * Base of the itemSelection store. Contains all necessary data that is not computed.
   */
  const selectedItemIds = ref<selectedItemIds[]>([]);

  interface selectedItemIds {
    id: string;
  }

  /**
   * Get price total of sub products
   */
  const itemsTotal = computed(() => {
    let total = 0;

    selectedItemIds.value.forEach((item) => {
      let subProductData = searchSubProductId(item.id);
      if (subProductData) {
        total += Number.parseFloat(subProductData.price);
      }
    });

    return total;
  });

  /**
   * Method to search sub product info by id
   * @param subProductId
   */
  const searchSubProductId = (subProductId: string): any | null => {
    for (const item of productData.value ?? []) {
      for (const product of item.products) {
        for (const subProduct of product.subProducts ?? []) {
          if (subProduct.id === subProductId) {
            return subProduct;
          }
        }
      }
    }
    return null;
  };

  function clearItems() {
    selectedItemIds.value = [];
  }

  /**
   * Function to select or deselect an item
   * @param subItem
   * @param product
   */
  const selectItem = (subItem: ShopProduct, product: ShopProduct) => {
    const index = selectedItemIds.value.findIndex(
      (item) => item.id === subItem.id
    );

    // If the item is not already selected
    if (index === -1) {
      if (!product.multiple_items_selectable) {
        selectedItemIds.value = [{ id: subItem.id }];
      } else {
        selectedItemIds.value.push({ id: subItem.id });
      }
    } else {
      selectedItemIds.value.splice(index, 1);
    }
  };

  return {
    selectedItemIds,
    itemsTotal,
    clearItems,
    selectItem,
    searchSubProductId,
  };
});
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useItemSelectionStore, import.meta.hot)
  );
}
