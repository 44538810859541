<script lang="ts" setup>
useHead({
  bodyAttrs: {
    class: "overflow-fix",
  },
});
</script>
<template>
  <NuxtLoadingIndicator color="black" />
  <NuxtLayout>
    <v-app>
      <NuxtPage />
    </v-app>
  </NuxtLayout>
</template>
<style>
.overflow-fix {
  overflow: hidden;
}
</style>
